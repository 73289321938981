import Vue from "vue";
import router from "@/router";
import App from "./App.vue";
import "@/assets/scss/Index.scss";
import VuePageTransition from "vue-page-transition";
import ElementUI from "element-ui";
Vue.use(ElementUI);
import "element-ui/lib/theme-chalk/index.css";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();
Vue.use(VuePageTransition);
// import VueSweetalert2 from "vue-sweetalert2";
// import "@/core-components/assets/scss/index.scss";
// import MenuIcon from "vue-material-design-icons/Menu.vue";
// import "vue-material-design-icons/styles.css";
// Vue.component("menu-icon", MenuIcon);
// If you don't need the styles, do not connect
// import "sweetalert2/dist/sweetalert2.min.css";
// import "boxicons/css/boxicons.min.css";
import Vuesax from "vuesax";
// import VueMaterial from "vue-material";
// import "vue-material/dist/vue-material.min.css";
// import "vue-material/dist/theme/default.css";

// Vue.use(VueMaterial);
// import VueQuillEditor from "vue-quill-editor";

// import "quill/dist/quill.core.css"; // import styles
// import "quill/dist/quill.snow.css"; // for snow theme
// import "quill/dist/quill.bubble.css"; // for bubble theme

import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

import VueMeta from "vue-meta";
Vue.use(VueMeta);

//vee-validate to validate fields
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

// Vue.use(VueQuillEditor /* { default global options } */);
import "vuesax/dist/vuesax.css"; //Vuesax styles
Vue.use(Vuesax, {
  // options here
});
require("vue2-animate/dist/vue2-animate.min.css");
// Vue.use(VueSweetalert2);
import "./assets/scss/tailwind.css";
Vue.config.productionTip = false;
// Vuex Store
import store from "./store/index.js";

new Vue({
  store,
  router,
  Vuesax,
  render: (h) => h(App),
}).$mount("#app");
