<template>
  <div class="lg:mt-24 md:mt-24 mt-20">
    <router-view />
    <div class="fixed lg:bottom-8 md:bottom-8 right-0 bottom-8 px-8">
      <div class="flex justify-end gap-4">
        <div class="grid gap-2">
          <a href="tel:9326295305">
            <img
              src="@/assets/images/bcube/phone-call.png"
              alt="whatsApp"
              class="w-12 h-12"
            />
          </a>
          <a
            href="https://wa.me/9326295305?text=Hello%20I%20need%20more%20information%20about%20@B-cube%20Pharmacy%20Academy"
            target="_blank"
          >
            <img
              src="@/assets/images/bcube/whats.png"
              alt="whatsApp"
              class="w-12 h-12"
            />
          </a>
          <!-- <p @click="handleClick" class="cursor-pointer">
            <img
              src="@/assets/images/Gallery/contact-mail.png"
              alt="ContactDetail"
              class="w-12 h-12"
            />
          </p> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Home | Bcubepharmacy",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Home description.",
      },
    ],
  },
  methods: {
    handleClick() {
      this.$router.push({ name: "contact" });
    },
  },
};
</script>

<style></style>
