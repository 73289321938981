import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    // {
    //   path: "/",
    //   name: "main",
    //   component: () => import("@/main/Main.vue"),
    //   redirect: "home",
    //   children: [
    //     {
    //       path: "home",
    //       name: "Home",
    //       component: () => import("@/pages/home/Home.vue"),
    //     },
    //   ],
    // },

    {
      path: "/",
      name: "whitenavbar",
      component: () => import("@/main/MainDark.vue"),
      redirect: "home",
      children: [
        {
          path: "home",
          name: "Home",
          component: () => import("@/pages/home/Home.vue"),
        },
        {
          path: "about",
          name: "about",
          component: () => import("@/pages/about/About.vue"),
        },
        {
          path: "gallery",
          name: "gallery",
          component: () => import("@/pages/gallery/Gallery.vue"),
        },
        {
          path: "contact-us",
          name: "contact",
          component: () => import("@/pages/contact/Contact.vue"),
        },
        {
          path: "B-pharma",
          name: "Bpharma",
          component: () => import("@/pages/Bpharma/bpharma.vue"),
        },

        {
          path: "D-pharma",
          name: "Dpharma",
          component: () => import("@/pages/Dpharma/Dpharma.vue"),
        },
        {
          path: "result",
          name: "result",
          component: () => import("@/pages/result/Result.vue"),
        },
        {
          path: "placement",
          name: "placement",
          component: () => import("@/pages/placement/Placement.vue"),
        },
        {
          path: "privacy-policy",
          name: "privacyPolicy",
          component: () => import("@/pages/PrivacyPolicy.vue"),
        },
        {
          path: "term&condition",
          name: "term",
          component: () => import("@/pages/Term.vue"),
        },
      ],
    },
  ],
});
export default router;
